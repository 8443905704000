import { defineComponent } from 'vue'
import './index.scss'
import { GfrTitle, ModuleBase } from '#components'
import { moduleBaseProps } from '@/components/module/base'

export default defineComponent({
  name: 'ModuleHomeScreen1',
  props: {
    ...moduleBaseProps,
    content: String
  },
  setup(props, { slots }) {
    return () => (
      <ModuleBase
        name={props.name}
        class={'module-home-intro-base'}
        hasVideo={props.backgroundVideo !== ''}
        video={props.backgroundVideo}
        desktopBackground={props.image_pc}
        mobileBackground={props.image_mobile}
        videoPoster={props.image_pc}
      >
        <section class={['module-home-intro']}>
          {props.content && (
            <GfrTitle level={2} class={'module-home-intro-slogan'}>
              {props.content}
            </GfrTitle>
          )}
          {slots.default?.()}
        </section>
      </ModuleBase>
    )
  }
})
